import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, get, set, onValue } from 'firebase/database';
import { auth } from './firebase';

const UserManagement = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const db = getDatabase();
    const usersRef = ref(db, 'users');
    const unsubscribe = onValue(usersRef, (snapshot) => {
      const usersData = snapshot.val();
      if (usersData) {
        const usersArray = Object.entries(usersData).map(([uid, data]) => ({
          uid,
          ...data,
          active: data.active || false
        }));
        setUsers(usersArray);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleCreateUser = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const db = getDatabase();
      await set(ref(db, `users/${userCredential.user.uid}`), {
        email: userCredential.user.email,
        active: true
      });
      setSuccess('User created successfully');
      setEmail('');
      setPassword('');
      setError(null);
    } catch (error) {
      setError(error.message);
      setSuccess(null);
    }
  };

  const handleToggleActivation = async (uid, currentStatus) => {
    try {
      const db = getDatabase();
      await set(ref(db, `users/${uid}/active`), !currentStatus);
      setSuccess(`User ${!currentStatus ? 'activated' : 'deactivated'} successfully`);
      setError(null);
    } catch (error) {
      console.error("Error toggling activation:", error);
      setError("Failed to update user status. Please try again.");
      setSuccess(null);
    }
  };

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', overflowY: 'auto' }}>
      <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', maxWidth: '500px', width: '100%' }}>
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>User Management</h2>
        {error && <p style={{ color: 'red', marginBottom: '10px' }}>{error}</p>}
        {success && <p style={{ color: 'green', marginBottom: '10px' }}>{success}</p>}
        
        <h3>Create New User</h3>
        <form onSubmit={handleCreateUser} style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '20px' }}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
          />
          <button type="submit" style={{ width: '100%', padding: '10px', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '5px' }}>
            Create User
          </button>
        </form>

        <h3>Manage Users</h3>
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {users.map((user) => (
            <div key={user.uid} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
              <span>{user.email} {!user.active && <span style={{ color: 'red' }}>(Inactive)</span>}</span>
              <button 
                onClick={() => handleToggleActivation(user.uid, user.active)}
                style={{ 
                  padding: '5px 10px', 
                  backgroundColor: user.active ? '#dc3545' : '#28a745', 
                  color: 'white', 
                  border: 'none', 
                  borderRadius: '5px' 
                }}
              >
                {user.active ? 'Deactivate' : 'Activate'}
              </button>
            </div>
          ))}
        </div>

        <button onClick={onClose} style={{ width: '100%', padding: '10px', backgroundColor: '#6c757d', color: 'white', border: 'none', borderRadius: '5px', marginTop: '20px' }}>
          Close
        </button>
      </div>
    </div>
  );
};

export default UserManagement;