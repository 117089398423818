import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyBVgUgEQoj_dNOwwqOILLIgz7WrcAjQapM",
    authDomain: "church-inventory-16508.firebaseapp.com",
    databaseURL: "https://church-inventory-16508-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "church-inventory-16508",
    storageBucket: "church-inventory-16508.appspot.com",
    messagingSenderId: "1021388116559",
    appId: "1:1021388116559:web:369a5ac5ef8b165712103a"
  };

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);

export { database, auth };