import React, { useState, useEffect } from 'react';
import { ref, get, child, set, push } from 'firebase/database';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { database, auth } from './firebase';
import jsPDF from 'jspdf';
import Login from './Login';
import UserManagement from './UserManagement';
import InventoryManagement from './InventoryManagement';

const InventoryChecklist = ({ items, onToggle }) => (
  <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '1rem' }}>
    {items.map(item => (
      <div key={item.id} style={{ border: '1px solid #ccc', padding: '1rem', display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '50px', height: '50px', marginRight: '1rem', overflow: 'hidden' }}>
          <img src={item.image} alt={item.title} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </div>
        <div>
          <h3 style={{ marginBottom: '0.2rem' }}>{item.title}</h3>
          <p style={{ fontSize: '0.8em', color: '#666', margin: 0 }}>{item.description}</p>
        </div>
        <input
          type="checkbox"
          checked={item.checked}
          onChange={() => onToggle(item.id)}
          style={{ marginLeft: 'auto' }}
        />
      </div>
    ))}
  </div>
);

const App = () => {
  const [user, setUser] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedService, setSelectedService] = useState(null);
  const [inventory, setInventory] = useState([]);
  const [reports, setReports] = useState({
    morning: {},
    afternoon: {}
  });
  const [reportText, setReportText] = useState('');
  const [publicLink, setPublicLink] = useState('');
  const [showInventoryManagement, setShowInventoryManagement] = useState(false);
  const [showUserManagement, setShowUserManagement] = useState(false);
  const [publicReport, setPublicReport] = useState(null);
  const [showChecklist, setShowChecklist] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const dbRef = ref(database);
        const snapshot = await get(child(dbRef, `users/${currentUser.uid}`));
        
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setIsActive(userData.active === true);
        } else {
          setIsActive(false);
        }
      } else {
        setUser(null);
        setIsActive(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user && isActive) {
      const dbRef = ref(database);
      Promise.all([
        get(child(dbRef, 'reports')),
        get(child(dbRef, 'inventoryItems'))
      ]).then(([reportsSnapshot, inventorySnapshot]) => {
        if (reportsSnapshot.exists()) {
          setReports(reportsSnapshot.val());
        }
        if (inventorySnapshot.exists()) {
          setInventory(Object.entries(inventorySnapshot.val()).map(([id, data]) => ({ id, ...data, checked: false })));
        }
      }).catch((error) => {
        console.error(error);
      });
    }
  }, [user, isActive]);

  const handleServiceSelection = (service) => {
    setSelectedService(service);
    setShowChecklist(true); // Show the checklist when a service is selected
    if (reports[service] && reports[service][selectedDate]) {
      const reportedItems = reports[service][selectedDate];
      setInventory(inventory.map(item => ({
        ...item,
        checked: reportedItems.some(reportedItem => reportedItem.id === item.id && reportedItem.checked)
      })));
    } else {
      setInventory(inventory.map(item => ({ ...item, checked: false })));
    }
  };

  const handleItemToggle = (itemId) => {
    setInventory(inventory.map(item => 
      item.id === itemId ? { ...item, checked: !item.checked } : item
    ));
  };

  const handleSubmit = () => {
    const updatedReports = {
      ...reports,
      [selectedService]: {
        ...reports[selectedService],
        [selectedDate]: inventory.filter(item => item.checked)
      }
    };
    setReports(updatedReports);
    
    set(ref(database, 'reports'), updatedReports)
      .then(() => {
        console.log('Data saved successfully');
        alert('Report submitted successfully!');
      })
      .catch((error) => {
        console.error('The write failed...', error);
        alert('Failed to submit report. Please try again.');
      });
  };

  const compareInventory = () => {
    const morningItems = reports.morning[selectedDate] || [];
    const afternoonItems = reports.afternoon[selectedDate] || [];
    
    const morningOnly = morningItems.filter(item => 
      item.checked && !afternoonItems.some(afternoonItem => 
        afternoonItem.id === item.id && afternoonItem.checked
      )
    );

    const afternoonOnly = afternoonItems.filter(item => 
      item.checked && !morningItems.some(morningItem => 
        morningItem.id === item.id && morningItem.checked
      )
    );

    return { morningOnly, afternoonOnly };
  };

  const generateReport = async () => {
    const morningReport = reports.morning[selectedDate] || [];
    const afternoonReport = reports.afternoon[selectedDate] || [];
    const { morningOnly, afternoonOnly } = compareInventory();
  
    // Create public report
    const publicReportData = {
      date: selectedDate,
      morningItems: morningReport.filter(item => item.checked),
      afternoonItems: afternoonReport.filter(item => item.checked),
      morningReturn: morningOnly,
      afternoonTake: afternoonOnly
    };
  
    const publicReportRef = push(ref(database, 'publicReports'));
    await set(publicReportRef, publicReportData);
  
    const publicLinkUrl = `${window.location.origin}/public-report/${publicReportRef.key}`;
    setPublicLink(publicLinkUrl);
  
    let report = `FPC Worship Inventory Report for ${selectedDate}\n\n`;
    // report += `Public Link: ${publicLinkUrl}\n\n`;
    report += `Morning Service:\n`;
    report += morningReport.length > 0 
      ? morningReport.filter(item => item.checked).map(item => `- ${item.title}`).join('\n')
      : 'No items reported';
    report += `\n\nAfternoon Service:\n`;
    report += afternoonReport.length > 0
      ? afternoonReport.filter(item => item.checked).map(item => `- ${item.title}`).join('\n')
      : 'No items reported';
    
    report += '\n\nItems to Return to Storage (Morning Team):\n';
    report += morningOnly.length > 0
      ? morningOnly.map(item => `- ${item.title}`).join('\n')
      : 'No items to return';
  
    report += '\n\nItems to be taken by Afternoon Team:\n';
    report += afternoonOnly.length > 0
      ? afternoonOnly.map(item => `- ${item.title}`).join('\n')
      : 'No items to be taken';
  
      setShowChecklist(false); // Hide the checklist
      setReportText(report);
  
    // Generate PDF
    const pdf = new jsPDF();
    pdf.setFontSize(12);
    const splitText = pdf.splitTextToSize(report, 180);
    pdf.text(splitText, 15, 15);
    pdf.save(`inventory_report_${selectedDate}.pdf`);
  
    console.log('Generated Report:', report);
    alert('Report generated! PDF downloaded and public report created.');
  };

  const handleLogin = () => {
    console.log('Logged in successfully');
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      console.log('Logged out successfully');
      setUser(null);
      setIsActive(false);
    }).catch((error) => {
      console.error('Logout error:', error);
    });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(reportText).then(() => {
      alert('Report copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Login onLogin={handleLogin} />;
  }

  if (!isActive) {
    return (
      <div style={{ maxWidth: '300px', margin: '100px auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', textAlign: 'center' }}>
        <h2>Account Not Activated</h2>
        <p>Your account has not been activated yet. Please contact an administrator.</p>
        <button onClick={handleLogout} style={{ marginTop: '20px', padding: '10px', backgroundColor: '#dc3545', color: 'white', border: 'none', borderRadius: '5px' }}>
          Logout
        </button>
      </div>
    );
  }

  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '1rem' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>FPC Worship Inventory Checklist</h1>
        <div>
          <button onClick={() => setShowUserManagement(true)} style={{ marginRight: '10px', padding: '0.5rem', backgroundColor: '#17a2b8', color: 'white', border: 'none', borderRadius: '5px' }}>
            Manage Users
          </button>
          <button onClick={handleLogout} style={{ padding: '0.5rem', backgroundColor: '#dc3545', color: 'white', border: 'none', borderRadius: '5px' }}>
            Logout
          </button>
        </div>
      </div>
      
      <div style={{ marginBottom: '1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <label htmlFor="date">Select Date: </label>
          <input 
            type="date" 
            id="date"
            value={selectedDate} 
            onChange={(e) => setSelectedDate(e.target.value)}
            style={{ marginLeft: '0.5rem' }}
          />
        </div>
        <button onClick={() => setShowInventoryManagement(true)} style={{ padding: '0.5rem', backgroundColor: '#17a2b8', color: 'white', border: 'none', borderRadius: '5px' }}>
          Manage Inventory
        </button>
      </div>

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '0.5rem', marginBottom: '1rem' }}>
        <button onClick={() => handleServiceSelection('morning')} style={{ padding: '0.5rem', backgroundColor: selectedService === 'morning' ? '#007bff' : '#f8f9fa', color: selectedService === 'morning' ? 'white' : 'black' }}>
          Morning Service
        </button>
        <button onClick={() => handleServiceSelection('afternoon')} style={{ padding: '0.5rem', backgroundColor: selectedService === 'afternoon' ? '#007bff' : '#f8f9fa', color: selectedService === 'afternoon' ? 'white' : 'black' }}>
          Afternoon Service
        </button>
        <button onClick={generateReport} style={{ padding: '0.5rem', backgroundColor: '#f8f9fa' }}>
          Generate Report
        </button>
      </div>

      {selectedService && showChecklist && (
        <>
          <h2 style={{ fontSize: '1.2rem', fontWeight: 'semibold', marginBottom: '0.5rem' }}>Inventory Checklist</h2>
          <InventoryChecklist items={inventory} onToggle={handleItemToggle} />
          <button onClick={handleSubmit} style={{ marginTop: '1rem', padding: '0.5rem', backgroundColor: '#28a745', color: 'white', width: '100%' }}>Submit Report</button>
        </>
      )}

      {reportText && !showChecklist && (
        <div style={{ marginTop: '1rem' }}>
          <h2 style={{ fontSize: '1.2rem', fontWeight: 'semibold', marginBottom: '0.5rem' }}>Generated Report</h2>
          <textarea 
            value={reportText} 
            readOnly 
            style={{ width: '100%', height: '300px', marginBottom: '0.5rem' }}
          />
          <button onClick={copyToClipboard} style={{ padding: '0.5rem', backgroundColor: '#17a2b8', color: 'white', border: 'none', borderRadius: '5px', marginRight: '0.5rem' }}>
            Copy to Clipboard
          </button>
          <button onClick={() => setShowChecklist(true)} style={{ padding: '0.5rem', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '5px' }}>
            Back to Checklist
          </button>
        </div>
      )}

      {publicReport && (
        <div style={{ marginTop: '1rem', border: '1px solid #ccc', padding: '1rem', borderRadius: '5px' }}>
          <h2>Public Report for {publicReport.date}</h2>
          <h3>Morning Service Items:</h3>
          <ul>
            {publicReport.morningItems.map((item, index) => (
              <li key={index}>
                <input type="checkbox" id={`morning-${index}`} />
                <label htmlFor={`morning-${index}`}>{item.title}</label>
              </li>
            ))}
          </ul>
          <h3>Afternoon Service Items:</h3>
          <ul>
            {publicReport.afternoonItems.map((item, index) => (
              <li key={index}>
                <input type="checkbox" id={`afternoon-${index}`} />
                <label htmlFor={`afternoon-${index}`}>{item.title}</label>
              </li>
            ))}
          </ul>
          <h3>Items to Return (Morning Team):</h3>
          <ul>
            {publicReport.morningReturn.map((item, index) => (
              <li key={index}>
                <input type="checkbox" id={`morning-return-${index}`} />
                <label htmlFor={`morning-return-${index}`}>{item.title}</label>
              </li>
            ))}
          </ul>
          <h3>Items to be taken by Afternoon Team:</h3>
          <ul>
            {publicReport.afternoonTake.map((item, index) => (
              <li key={index}>
                <input type="checkbox" id={`afternoon-take-${index}`} />
                <label htmlFor={`afternoon-take-${index}`}>{item.title}</label>
              </li>
            ))}
          </ul>
        </div>
      )}

      {showUserManagement && (
        <UserManagement onClose={() => setShowUserManagement(false)} />
      )}

      {showInventoryManagement && (
        <InventoryManagement onClose={() => setShowInventoryManagement(false)} />
      )}
    </div>
  );
};

export default App;