import React, { useState, useEffect } from 'react';
import { ref, set, get, remove, update } from 'firebase/database';
import { database } from './firebase';  // Change this line

const InventoryManagement = ({ onClose }) => {
  const [inventoryItems, setInventoryItems] = useState([]);
  const [newItem, setNewItem] = useState({ title: '', description: '', image: '' });
  const [editingItem, setEditingItem] = useState(null);

  useEffect(() => {
    // Load inventory items from Firebase
    const dbRef = ref(database, 'inventoryItems');
    get(dbRef).then((snapshot) => {
      if (snapshot.exists()) {
        setInventoryItems(Object.entries(snapshot.val()).map(([id, data]) => ({ id, ...data })));
      }
    }).catch((error) => {
      console.error("Error loading inventory items:", error);
    });
  }, []);

  const handleAddItem = () => {
    if (newItem.title && newItem.description && newItem.image) {
      const newItemRef = ref(database, `inventoryItems/${Date.now()}`);
      set(newItemRef, newItem).then(() => {
        setInventoryItems([...inventoryItems, { id: Date.now().toString(), ...newItem }]);
        setNewItem({ title: '', description: '', image: '' });
      }).catch((error) => {
        console.error("Error adding new item:", error);
      });
    }
  };

  const handleRemoveItem = (id) => {
    const itemRef = ref(database, `inventoryItems/${id}`);
    remove(itemRef).then(() => {
      setInventoryItems(inventoryItems.filter(item => item.id !== id));
    }).catch((error) => {
      console.error("Error removing item:", error);
    });
  };

  const handleEditItem = (item) => {
    setEditingItem(item);
  };

  const handleUpdateItem = () => {
    if (editingItem) {
      const itemRef = ref(database, `inventoryItems/${editingItem.id}`);
      update(itemRef, {
        title: editingItem.title,
        description: editingItem.description,
        image: editingItem.image
      }).then(() => {
        setInventoryItems(inventoryItems.map(item => 
          item.id === editingItem.id ? editingItem : item
        ));
        setEditingItem(null);
      }).catch((error) => {
        console.error("Error updating item:", error);
      });
    }
  };

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', overflowY: 'auto' }}>
      <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', maxWidth: '500px', width: '100%' }}>
        <h2 style={{ marginBottom: '20px' }}>Manage Inventory Items</h2>
        
        <div style={{ marginBottom: '20px' }}>
          <input
            type="text"
            placeholder="Item Title"
            value={newItem.title}
            onChange={(e) => setNewItem({ ...newItem, title: e.target.value })}
            style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
          />
          <input
            type="text"
            placeholder="Item Description"
            value={newItem.description}
            onChange={(e) => setNewItem({ ...newItem, description: e.target.value })}
            style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
          />
          <input
            type="text"
            placeholder="Image URL"
            value={newItem.image}
            onChange={(e) => setNewItem({ ...newItem, image: e.target.value })}
            style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
          />
          <button onClick={handleAddItem} style={{ width: '100%', padding: '10px', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '5px' }}>
            Add Item
          </button>
        </div>
        
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {inventoryItems.map((item) => (
            <div key={item.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <img src={item.image} alt={item.title} style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '10px' }} />
              <div style={{ flexGrow: 1 }}>
                <div>{item.title}</div>
                <div style={{ fontSize: '0.8em', color: '#666' }}>{item.description}</div>
              </div>
              <button onClick={() => handleEditItem(item)} style={{ padding: '5px 10px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '5px', marginRight: '5px' }}>
                Edit
              </button>
              <button onClick={() => handleRemoveItem(item.id)} style={{ padding: '5px 10px', backgroundColor: '#dc3545', color: 'white', border: 'none', borderRadius: '5px' }}>
                Remove
              </button>
            </div>
          ))}
        </div>
        
        {editingItem && (
          <div style={{ marginTop: '20px', borderTop: '1px solid #ccc', paddingTop: '20px' }}>
            <h3>Edit Item</h3>
            <input
              type="text"
              value={editingItem.title}
              onChange={(e) => setEditingItem({ ...editingItem, title: e.target.value })}
              style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
            />
            <input
              type="text"
              value={editingItem.description}
              onChange={(e) => setEditingItem({ ...editingItem, description: e.target.value })}
              style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
            />
            <input
              type="text"
              value={editingItem.image}
              onChange={(e) => setEditingItem({ ...editingItem, image: e.target.value })}
              style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
            />
            <button onClick={handleUpdateItem} style={{ width: '100%', padding: '10px', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '5px' }}>
              Update Item
            </button>
          </div>
        )}
        
        <button onClick={onClose} style={{ width: '100%', padding: '10px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '5px', marginTop: '20px' }}>
          Close
        </button>
      </div>
    </div>
  );
};

export default InventoryManagement;